import React from "react";
import Navbar from "../modules/Navbar/Navbar";
import Additionally from "../modules/Additionally/Additionally";
import Welcome from "../modules/Welcome/Welcome";
import About from "../modules/About/About";
import Program from "../modules/Program/Program";
import Letter from "../modules/Letter/Letter";
import FAQ from "../modules/FAQ/FAQ";
import Contacts from "../modules/Contacts/Contacts";
import ApplicationForm from "../modules/ApplicationForm/ApplicationForm";
import Swiper from "../modules/Swiper/Swiper";

function App() {
  return (
    <div className="w-full relative flex flex-col items-center">
      <Navbar />
      <div className="absolute w-full max-w-[1920px] top-[81px]">
        <Welcome />
        <About />
        <Program />
        <ApplicationForm />
        <Swiper />
        <Additionally />
        <Letter />
        <FAQ />
        <Contacts />
      </div>
    </div>
  );
}

export default App;
