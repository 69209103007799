import React from 'react'
import programCircle from '../../../shared/assets/images/programCircle.png';

const ContentCircle = () => {
  return (
    <div className='w-[90%] lg:w-[45%] max-w-[620px] self-center lg:self-auto mb-10 lg:mb-0'>
      <img src={programCircle} alt="Program Circle" className='w-full sticky top-0 lg:pt-[81px]'/>
    </div>
  )
}

export default ContentCircle