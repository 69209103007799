import React, { useCallback, useEffect, useRef } from "react";
import logo from "../../../shared/assets/images/logo.png";
import { ReactComponent as CircleIcon1 } from "../../../shared/assets/icons/circleIconWelcome.svg";
import { ReactComponent as CircleIcon2 } from "../../../shared/assets/icons/circleIconDescription.svg";
import styles from "./letterModal.module.scss";
import clsx from "clsx";

type LetterModalProps = {
  onClose: () => void;
};

const LetterModal = (props: LetterModalProps) => {
  const { onClose } = props;
  const modalRef = useRef<any>();

    const handleEscape = useCallback((event: any) => {
      if (event.key === "Escape") {
        onClose();
      }
    }, [onClose]);
  

  useEffect(() => {
   

    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleKeyPress = (event: any) => {
      handleEscape(event);
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleEscape, onClose]);

  return (
    <div className={clsx(styles.modalOverlay)}>
      <div
        ref={modalRef}
        className="max-w-[780px] max-h-[700px] w-[90%] lg:w-[60%] h-[80%] bg-bgMain overflow-hidden border border-white rounded-[40px] relative cursor-default"
      >
        <CircleIcon1 className="absolute w-[20%] left-[-30px] top-5" />
        <CircleIcon2 className="absolute w-[30%] right-[-30px] bottom-[-170px]" />
        <div className="h-full px-10 py-10 flex flex-col items-center justify-center">
          <img src={logo} alt="Logo" className="w-[25%] max-w-[486px] mb-5 lg:mb-10" />
          <p className="mb-6 text-[32px] lg:text-[48px]">Привет , молодые чемпионы!</p>
          <div className="flex-grow mb-14 w-[80%] lg:w-[70%] overflow-y-scroll">
            <p className="mb-5">
              Мы - Media Buying команда компании Unity, и у нас есть
              предложение, которое может перевернуть ваше профессиональное
              становление. Знаешь ли ты, кто такой Media Buyer? Если да, то ты
              уже на полпути к успеху! Но не переживай, даже если это для тебя
              новое слово, мы здесь, чтобы помочь. Мы готовы сделать первые шаги
              с тобой, поделиться опытом и научить, как избежать ошибок.
            </p>
            <p>
              Представьте, что вы занимаетесь работой из любой точки мира, а при
              этом ваши доходы могут стать просто ошеломляющими! Мы
              специализируемся на арбитраже трафика в страны Tier1 через
              Facebook, работаем в сферах Nutra и Dating - это одни из самых
              прибыльных сфер сегодняшнего мира. Сфера развивается стремительно,
              но специалистов ей всегда не хватает. Ты можешь совершить круто
              бустануть в этой CPA маркетинге и быть с нами одним из
              первопроходцев в Казахстане. Unity готовы обучить тебя всему
              необходимому для успешной карьеры в Media Buying и показать, как
              добиться успеха. Готов начать свой путь к финансовой независимости
              и свободе? Присоединяйся к нам и оставляй заявку!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LetterModal;