import React, { useRef, useState } from "react";
import { ReactComponent as OutlinedArrowIcon } from "../../shared/assets/icons/outlineArrowIcon.svg";
import { ReactComponent as FilledArrowIconLeft } from "../../shared/assets/icons/filledArrowIconLeft.svg";
import { ReactComponent as FilledArrowIconRight } from "../../shared/assets/icons/filledArrowIconRight.svg";
import { ReactComponent as CircleIcon } from '../../shared/assets/icons/circleIconApplicationForm.svg';
import clsx from "clsx";
import useWindowDimensions from "../../shared/helpers/useWidth";

const ListStyles = "py-10 flex items-center justify-center space-x-5 px-0";
const textStyles = "";

const links = [
  { text: "Подача онлайн заявки", link: "" },
  { text: "Заполнить анкету-опросник", link: "" },
  { text: "Пройти интервью с со специалистом", link: "" },
];

const Swiper = () => {
  const nextRef = useRef<any>();
  const [indexes, setIndexes] = useState({
    1: 0,
    2: 1,
    3: 2,
  });
  const clickHandlerPrev = () => {
    setIndexes({
      1: indexes["1"] > 0 ? indexes["1"] - 1 : links.length - 1,
      2: indexes["2"] > 0 ? indexes["2"] - 1 : links.length - 1,
      3: indexes["3"] > 0 ? indexes["3"] - 1 : links.length - 1,
    });
  };

  const clickHandlerNext = () => {
    setIndexes({
      1: indexes["1"] < links.length - 1 ? indexes["1"] + 1 : 0,
      2: indexes["2"] < links.length - 1 ? indexes["2"] + 1 : 0,
      3: indexes["3"] < links.length - 1 ? indexes["3"] + 1 : 0,
    });
  };

  return (
    <div
      className={clsx(
        "pt-[81px] w-full flex flex-col items-center justify-center relative pb-10 lg:pb-20 overflow-hidden"
      )}
    >
      <div className="w-full flex mb-5 bg-secondary justify-around items-center space-x-5 z-10">
        <div className={ListStyles}>
          <p className={textStyles}>{links[indexes["1"]].text}</p>
        </div>
        {useWindowDimensions().width > 1024 && <OutlinedArrowIcon />}
        {useWindowDimensions().width > 1024 && (
          <>
            <div className={ListStyles}>
              <p className={textStyles}>{links[indexes["2"]].text}</p>
            </div>
            <OutlinedArrowIcon />
            <div className={ListStyles}>
              <p className={textStyles}>{links[indexes["3"]].text}</p>
            </div>
          </>
        )}
      </div>
      <div className="flex space-x-5">
        <button onClick={clickHandlerPrev}>
          <FilledArrowIconLeft className="" />
        </button>
        <button onClick={clickHandlerNext} ref={nextRef}>
          <FilledArrowIconRight className="" />
        </button>
      </div>
      <CircleIcon className="absolute lg:w-[250px] lg:h-[250px] w-[90px] h-[90px] right-[-25px] lg:right-[-30px] top-[150px] lg:top-[110px]" />
    </div>
  );
};

export default Swiper;
