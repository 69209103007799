import React from "react";
import Content from "./Content/Content";
import ContentCircle from "./ContentCircle/ContentCircle";
import LetterO from "../../shared/ui/LetterCircles/LetterO";
import ContentWrapper from "../../shared/ui/ContentWrapper/ContentWrapper";

const Program = () => {
  return (
    <ContentWrapper>
      <div id="program" className="pt-[81px]">
        <div className="flex lg:justify-start justify-center">
          <h2 className="mb-10 inline-block">
            <span>
              в
              <LetterO />
              т,
            </span>
            <span>
              чт
              <LetterO />
            </span>{" "}
            тебя ждет
          </h2>
        </div>
        <div className="flex lg:flex-row lg:space-x-20 flex-col-reverse">
          <Content />
          <ContentCircle />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Program;
