import React, { useState } from "react";
import { ReactComponent as OpenedEye } from "../../shared/assets/icons/openedEyesIcon.svg";
import { ReactComponent as ClosedEye } from "../../shared/assets/icons/closedEyesIcon.svg";
import { ReactComponent as CircleIcon5 } from "../../shared/assets/icons/circleIcon5.svg";
import { ReactComponent as CircleIcon4 } from "../../shared/assets/icons/circleIcon4.svg";

import { questionAnswers } from "./constants";
import clsx from "clsx";
import ContentWrapper from "../../shared/ui/ContentWrapper/ContentWrapper";

const FAQ = () => {
  return (
    <div
      id="faq"
      className="pt-[81px] max-w-[1920px] w-full relative flex flex-col justify-center lg:justify-start overflow-hidden"
    >
      <ContentWrapper>
        <div className="flex lg:justify-start justify-center">
          <h2 className="mb-10">FAQ</h2>
        </div>
      </ContentWrapper>
      <CircleIcon5 className="hidden lg:block w-[208px] h-[208px] absolute left-[16%] top-[670px]" />
      <CircleIcon4 className="hidden lg:block w-[382px] h-[382px] absolute left-[-135px] top-[200px]" />
      <div className="w-full lg:w-[60%] ml-auto flex flex-col items-center justify-center">
        {questionAnswers.map((item) => (
          <ShowButton
            question={item.question}
            answer={item.answer}
            key={item.answer}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQ;

type ShowButtonProps = {
  question: string;
  answer: string;
};

const svgStyles =
  "w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] shrink-0 fill-secondary";

const ShowButton = (props: ShowButtonProps) => {
  const { question, answer } = props;
  const [isShow, setIsShow] = useState<boolean>(false);
  return (
    <div className="px-[35px] max-w-[800px] w-full border-t border-white relative">
      <button
        className={clsx(
          "flex justify-between items-center py-5 w-full space-x-5",
          isShow && "border-b border-white"
        )}
        onClick={() => setIsShow(!isShow)}
      >
        <h3 className="text-left z-10">{question}</h3>
        {isShow ? (
          <ClosedEye className={svgStyles} />
        ) : (
          <OpenedEye className={svgStyles} />
        )}
      </button>
      {isShow && <h3 className="py-6 font-light z-10">{answer}</h3>}
    </div>
  );
};
