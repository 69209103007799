import React from "react";

const Navbar = () => {
  const scrollToSection = (id: any) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <nav className="w-full max-w-[1920px] fixed mx-auto top-0 bg-bgMain z-20">
      <ul className="flex justify-around pt-8 pb-5 border-b border-white px-5">
        <li className="leading-none cursor-pointer" onClick={() => scrollToSection('about')}>
          О нас
        </li>
        <li className="leading-none cursor-pointer" onClick={() => scrollToSection('program')}>
          Программа
        </li>
        <li className="leading-none cursor-pointer" onClick={() => scrollToSection('faq')}>
          FAQ
        </li>
        <li className="leading-none cursor-pointer" onClick={() => scrollToSection('contacts')}>
          Контакты
        </li>
      </ul>  
    </nav>
  );
};

export default Navbar;
