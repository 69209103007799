import React from "react";
import ContentWrapper from "../../shared/ui/ContentWrapper/ContentWrapper";
import LetterO from "../../shared/ui/LetterCircles/LetterO";
import { ReactComponent as CircleIcon } from "../../shared/assets/icons/circleIconWelcome.svg";
import useWindowDimensions from "../../shared/helpers/useWidth";

const infoStyle =
  "py-[55px] px-6 border border-white rounded-[10px] w-full max-w-[620px] lg:max-w-[420px] flex items-center justify-center bg-bgMain z-10";
const textStyles = "text-center";

const Additionally = () => {
  return (
    <div className="pt-[81px] relative mb-20 w-full overflow-hidden">
      {useWindowDimensions().width > 1024 && (
        <CircleIcon className="absolute w-[284px] h-[284px] right-[-100px]" />
      )}
      <div className="flex lg:justify-start justify-center">
        <ContentWrapper>
          <div className="w-full flex justify-center lg:justify-start">
            <h2 className="mb-[38px] inline-block">
              <span>
                п<LetterO />
                чему
              </span>{" "}
              <span className="relative inline-block">
                <span className="non absolute top-[-25px] lg:static -rotate-12 lg:rotate-0">
                  non
                </span>{" "}
                университет?
              </span>
            </h2>
          </div>
        </ContentWrapper>
      </div>
      <ContentWrapper>
        <div className="flex flex-col lg:flex-row justify-center lg:space-x-[25px] items-center lg:items-stretch">
          <div className={infoStyle}>
            <p className={textStyles}>
              проводим серьезный отбор, чтобы взять только{" "}
              <span className="underline">самых мотивированных</span> и
              вкладываться в каждого
            </p>
          </div>
          <div className={infoStyle}>
            <p className={textStyles}>
              фокус на практические навыки для построения карьеры и оплачиваемая
              <span className="underline"> стажировка в лучшей CPA</span>
              -команде Казахстана
            </p>
          </div>
          <div className={infoStyle}>
            <p className={textStyles}>
              <span className="underline">
                современные темы и только актуальные
              </span>{" "}
              знания. Преподаватели-действующие team leads в CPA сфере, которые
              горят желанием поделиться своими знаниями
            </p>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default Additionally;
