import React from "react";
import Button from "../../shared/ui/Button/Button";
import LetterO from "../../shared/ui/LetterCircles/LetterO";
import ContentWrapper from "../../shared/ui/ContentWrapper/ContentWrapper";

const ApplicationForm = () => {
  return (
    <ContentWrapper>
      <div className="pt-[81px]">
        <div className="flex lg:flex-row flex-col space-y-5 lg:space-y-0 lg:space-x-10 justify-between items-center">
          <h2 className="inline-block">
            как{" "}
            <span>
              п<LetterO />
              пасть?
            </span>
          </h2>
          <Button
            text="Подать заявку"
            externalStyles="flex justify-center items-center border-secondary w-[273px] lg:w-[440px] h-[87px] hover:opacity-90"
            innerStyles="px-8 py-5 w-[236px] lg:w-[382px] bg-secondary rounded-[10px]"
            link="https://forms.gle/hLUC3rckSyQ4vCFj9"
          />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default ApplicationForm;
