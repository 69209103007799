export const questionAnswers = [
  {
    question: "Кто такие NON University?",
    answer:
      "Мы казахстанская CPA комания, которая занимается арбитражем трафика через Facebook по всему миру в вертикали Nutra и  Dating. Мы профессионалы, которые хотят делиться знаниями и помогать молодым талантам становиться успешными Media Buyers",
  },
  {
    question: "Кому и чем помогаем?",
    answer:
      "Мы поддерживаем молодых специалистов, предоставляя им доступ к актуальным знаниям, профессиональному опыту и возможность стажироваться, а в дальнейшем и работать в крутой команде профессионалов. Наша цель - помочь вам сделать первые и последующие шаги в сфере CPA мркетинга, обеспечивая поддержку и ресурсы, необходимые для вашего профессионального становления.",
  },
  {
    question:
      "Мне еще не исполнилось 18 лет, могу ли я попасть в NON Университет?",
    answer:
      "Придется дождаться следующего набора в NON University. Мы принимаем только тех, кому уже исполнилось 18 лет. Но ты можешь следить за нами в социальных сетях и первым узнавать о новостях ",
  },
  {
    question: "Обязательно ли быть в Алматы, чтобы попасть в Non Университет?",
    answer:
      "Текущий поток обучения и стажировочной программы рассчинан на ребят, которые живут в Алматы или планируют к нам в город в скором времени.",
  },
  {
    question: "Как узнать свои результаты отбора?",
    answer:
      "Отбор проводится с помощью нашего HR отдела. Мы будем оповещать вас о результатах и этапах отбора, следите внимательно за оповещениями в телеграм.",
  },
  {
    question: "Что я получу пройдя обучение и стажировку?",
    answer:
      "По окончанию обучения тебе будет предложена стажировочное задание, которое на практике покажет то чему ты научился. Ребят с наилучшими показателями мы будем готовы нанять на работу с окладом и ежемесечной премиальной частью.",
  },
  {
    question: "Куда писать, если у меня возник вопрос?",
    answer: "Напиши нам в тг @Aleksandra_HRD и мы ответим тебе в течение 24 часов.",
  },
];
