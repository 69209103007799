import React from "react";
import { ReactComponent as CircleIconDescription } from "../../../shared/assets/icons/circleIconDescription.svg";
import logo from "../../../shared/assets/images/logo.png";
import { ReactComponent as CircleIcon1 } from "../../../shared/assets/icons/circleIcon1.svg";
import { ReactComponent as CircleIcon2 } from "../../../shared/assets/icons/circleIcon2.svg";
import { ReactComponent as CircleIcon3 } from "../../../shared/assets/icons/circleIcon3.svg";
import LetterO from "../../../shared/ui/LetterCircles/LetterO";
import clsx from "clsx";
import useWindowDimensions from "../../../shared/helpers/useWidth";

const Description = () => {
  return (
    <div className="bg-bgMain lg:z-10 relative overflow-hidden">
      <Part1 />
      <div className="hidden lg:block h-[260px] border-b border-white overflow-hidden relative">
        <CircleIconDescription className="w-[314px] h-[314px] absolute right-[200px] top-4" />
      </div>
      <Part2 />
    </div>
  );
};

export default Description;

const infoStyles =
  "px-[50px] py-[36px] border border-white rounded-[10px] max-w-[620px] flex-1 ";

const Part1 = () => {
  return (
    <div className="w-full flex justify-center lg:border-b border-white mb-32">
      <div className="max-w-[1400px] w-full flex lg:flex-row flex-col-reverse items-centerh-full">
        <div className="flex flex-col w-full lg:w-[50%] self-stretch flex-1 grow justify-center items-center">
          <div className={infoStyles}>
            <p>
              Это не классический университет, у нас нет занудных преподавателей
              и никого не вызывают к доске.{" "}
              <span className="underline">
                Ты сам определяешь какие задачи выполнить
              </span>{" "}
              сейчас и как прожить эту жизнь
            </p>
          </div>
          <div className={infoStyles}>
            <p>
              Это команда единомышленников в которой хочется завести друзей,{" "}
              <span className="underline">
                развиваться и рости профессионально.{" "}
              </span>
              Источник уникальных знаний и опыта
            </p>
          </div>
          <div className={infoStyles}>
            <p>
              Возможность{" "}
              <span className="underline">
                научиться новой профессии, и заработать:
              </span>{" "}
              мы верим в таланты, когда‑то мы сами прошли сложный путь поиска
              своего пути, и это изменило нашу жизнь.
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center lg:px-[5%] lg:py-[10%] mb-10 lg:mb-0 lg:border-r border-white">
          <img
            src={logo}
            alt="Logo"
            className="w-[70%] lg:w-full max-w-[540px]"
          />
        </div>
      </div>
    </div>
  );
};

const externalStyles = "flex flex-col items-center justify-center relative mb-10 lg:mb-0";
const iconStyles = "w-[100px] h-[100px] absolute top-[25%] lg:block hidden";
const innerStyles = "flex lg:flex-row flex-col justify-center max-w-[1400px]";
const innerBorderStyles =
  "2xl:border-y border-white w-full flex justify-center max-w-[1920px]";
const innerInfoStyles =
  "border border-white rounded-[10px] py-[40px] px-[24px] max-w-[620px] w-full bg-bgMain z-10";
const textStyles = "text-center";

const Part2 = () => {
  return (
    <div>
      <div className={externalStyles}>
        <CircleIconDescription className="lg:hidden w-[160px] h-[160px] absolute left-[-50px] top-[-120px]" />
        <div className="flex items-center justify-center relative py-[42px] w-full">
          <CircleIcon1 className={clsx(iconStyles, "left-5")} />
          <h2 className="uppercase max-w-[700px] text-center">
            в <span className="non">non</span> университете ты{" "}
            <span>
              м<LetterO />
              жешь
            </span>
          </h2>
        </div>
        <div className={innerBorderStyles}>
          <div className={innerStyles}>
            <div className={clsx(innerInfoStyles, "lg:w-[30%]")}>
              <p className={textStyles}>
                <span className="underline">научиться профессии мечты, </span>
                которая позволит тебе сохранять work-life balance
              </p>
            </div>
            <div className={clsx(innerInfoStyles, "lg:w-[40%]")}>
              <p className={textStyles}>
                <span className="underline">стать частью dream team</span> и,
                бонусом, завести новых успешных друзей
              </p>
            </div>
            <div className={clsx(innerInfoStyles, "lg:w-[30%]")}>
              <p className={textStyles}>
                <span className="underline">самореализоваться</span> как
                личность и профессионал, начать свой карьерный путь
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={externalStyles}>
        <div className="flex items-center justify-center relative py-[42px] w-full">
          <h2 className="uppercase max-w-[700px] text-center inline-block">
            мы{" "}
            <span>
              п<LetterO />
              дх
              <LetterO />
              дим
            </span>{" "}
            друг другу, если
          </h2>
          <CircleIcon2 className={clsx(iconStyles, "right-5")} />
          <CircleIcon2 className="lg:hidden w-[100px] h-[100px] absolute top-[-70px] right-[-20px]" />
        </div>
        <div className={innerBorderStyles}>
          <div className={innerStyles}>
            <div className={clsx(innerInfoStyles, "lg:w-[30%]")}>
              <p className={textStyles}>
                тебе от 18 до 25 лет, ты учишься в колледже, вузе, или еще не
                выбрал, свой путь в карьере
              </p>
            </div>
            <div className={clsx(innerInfoStyles, "lg:w-[40%]")}>
              <p className={textStyles}>
                ты амбициозен и любопытен, любишь исследовать и воплощать свои
                идеи в жизнь
              </p>
            </div>
            <div className={clsx(innerInfoStyles, "lg:w-[30%]")}>
              <p className={textStyles}>
                ты уже имеешь базовые знания в сфере интернет маркетинга и
                знаешь что за зверь ADS manager
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={externalStyles}>
        <div className="flex items-center justify-center relative py-[42px] w-full">
          <CircleIcon3 className={clsx(iconStyles, "left-5")} />
          <CircleIcon3 className="lg:hidden w-[100px] h-[100px] absolute top-[-70px] left-[-20px]" />
          <h2 className="uppercase max-w-[800px] text-center inline-block">
            {useWindowDimensions().width > 1024 && (
              <span className="non">non</span>
            )}
            университет для тех,{" "}
            <span>
              кт
              <LetterO />
            </span>{" "}
            <span>
              х<LetterO />
              чет
            </span>
          </h2>
        </div>
        <div className={innerBorderStyles}>
          <div className={innerStyles}>
            <div className={clsx(innerInfoStyles, "lg:w-[30%]")}>
              <p className={textStyles}>
                прокачать навыки, чтобы стать{" "}
                <span className="underline">
                  высокооплачиваемым профессионалом
                </span>
              </p>
            </div>
            <div className={clsx(innerInfoStyles, "lg:w-[40%]")}>
              <p className={textStyles}>
                <span className="underline">
                  получить маркетинговые знания,
                </span>{" "}
                чтобы развиваться без лишнего стресса
              </p>
            </div>
            <div className={clsx(innerInfoStyles, "lg:w-[30%]")}>
              <p className={textStyles}>
                <span className="underline">создавать новые проекты,</span>{" "}
                чтобы влиять на мир и свое окружение
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
