import React from "react";
import { ReactComponent as CircleIcon2 } from "../../../shared/assets/icons/circleIcon2.svg";


const iconsStyleO =
  "absolute w-[14px] top-[-20px] left-[5px] sm:w-[24px] sm:top-[-14.5px] sm:left-[5.5px] lg:w-[35px] lg:top-[-8px] lg:left-[6.5px]";
const spanStyle = "relative";

const LetterO = () => {
  return (
    <span className={spanStyle}>
      <CircleIcon2 className={iconsStyleO} />о
    </span>
  );
};

export default LetterO;
