import React, { ReactNode } from "react";
import LetterS from "../../shared/ui/LetterCircles/LetterS";
import Button from "../../shared/ui/Button/Button";
import { ReactComponent as CircleIcon1 } from "../../shared/assets/icons/circleIcon1.svg";
import { ReactComponent as CircleIcon2 } from "../../shared/assets/icons/circleIcon2.svg";
import { ReactComponent as CircleIcon3 } from "../../shared/assets/icons/circleIcon3.svg";
import clsx from "clsx";

const iconStyles = "absolute w-[64px] h-[64px] shrink-0 bottom-1 lg:bottom-6";
const infoStyles = "text-center";

const Welcome = () => {
  return (
    <div className="flex flex-col items-center justify-center pt-[81px] lg:mb-0">
      <h1 className="inline-block mb-6 whitespace-nowrap lg:text-[100px] 2xl:text-[150px] relative">
        <span className="nonWelcome text-[36px] lg:text-[84px] 2xl:text-[128px] absolute -rotate-12 tracking-[6px] lg:tracking-[13px] 2xl:tracking-[18px]">
          non
        </span>
        универ
        <LetterS />
        итет
      </h1>
      <p className="mb-6">Твой старт в Affilate-marketing</p>
      <Button
        text="Подать заявку"
        externalStyles="flex justify-center items-center border-secondary w-[273px] lg:w-[440px] h-[87px] hover:opacity-90 mb-[76px]"
        innerStyles="px-8 py-5 w-[236px] lg:w-[382px] bg-secondary rounded-[10px]"
        link="https://forms.gle/hLUC3rckSyQ4vCFj9"
      />
      <div className="flex justify-center 2xl:border-y border-white w-full max-w-[1920px]">
        <div className="max-w-[1400px] w-full flex flex-col lg:flex-row justify-center items-center">
          <Advantage svg={<CircleIcon1 className={clsx(iconStyles, 'left-0 lg:left-auto')} />}>
            <p className={infoStyles}>
              <span className="underline">Оплачиваемое обучение</span> для
              талантливых людей
            </p>
          </Advantage>
          <Advantage svg={<CircleIcon2 className={clsx(iconStyles, 'right-0 lg:right-auto')} />}>
            <p className={infoStyles}>
              Пространство для безопасного{" "}
              <span className="underline">карьерного роста</span> и личного
              развития
            </p>
          </Advantage>
          <Advantage svg={<CircleIcon3 className={clsx(iconStyles, 'left-0 lg:left-auto')} />}>
            <p className={infoStyles}>
              Твой старт <span className="underline">к высоким доходам</span> с
              сохранением work-life balance
            </p>
          </Advantage>
        </div>
      </div>
    </div>
  );
};

export default Welcome;

type AdvantageProps = {
  children: ReactNode;
  svg: ReactNode;
};

const Advantage = (props: AdvantageProps) => {
  const { children, svg } = props;
  return (
    <div className="w-full max-w-[620px] lg:w-[33%] h-[124px] lg:h-[200px] border border-white rounded-[10px] py-6 px-10 relative">
      {children}
      {svg}
    </div>
  );
};
