import React, { ReactNode } from "react";

type ContentWrapperProps = {
  children: ReactNode;
};

const ContentWrapper = (props: ContentWrapperProps) => {
  const { children } = props;
  return (
    <div className="px-5 lg:px-20 w-full max-w-[1920px]">
      {children}
    </div>
  );
};

export default ContentWrapper;
