import React from "react";
import styles from "./button.module.scss";
import clsx from "clsx";

type ButtonProps = {
  text: string;
  externalStyles: string;
  innerStyles: string;
  link?: string;
  onClickHandler?: () => void;
};

const Button = (props: ButtonProps) => {
  const { text, externalStyles, innerStyles, link, onClickHandler } = props;
  return (
    <button
      className={clsx(
        styles.button,
        externalStyles,
        "rounded-[12px] border-[3px]"
      )}
      onClick={onClickHandler}
    >
      {link ? (
        <a href={link} target="blank">
          <div className={clsx(innerStyles)}>
            <p>{text}</p>
          </div>
        </a>
      ) : (
        <div className={clsx(innerStyles)}>
          <p>{text}</p>
        </div>
      )}
    </button>
  );
};

export default Button;
