import React, { useState } from "react";
import { content } from "./constants";
import { ReactComponent as ClosedEye } from "../../../shared/assets/icons/closedEyesIcon.svg";
import { ReactComponent as OpenedEye } from "../../../shared/assets/icons/openedEyesIcon.svg";
import clsx from "clsx";

const Content = () => {
  return (
    <div className="w-full lg:w-[45%] max-w-[620px] flex flex-col space-y-4 self-center">
      {content.map((item) => {
        return (
          <ShowButton
            key={item.heading}
            heading={item.heading}
            time={item.time}
            description={item.description}
            subTopic={item.subTopic}
            subDescription={item.subDescription}
          />
        );
      })}
    </div>
  );
};

export default Content;

type ShowButtonProps = {
  heading: string;
  time: string;
  description: string;
  subTopic: string;
  subDescription: string;
};

const ShowButton = (props: ShowButtonProps) => {
  const { heading, time, description, subTopic, subDescription } = props;
  const [isShow, setIsShow] = useState<boolean>(true);

  return (
    <div className="pl-6 pr-[34px] pt-5 pb-8 border border-white rounded-xl">
      <button
        className="flex justify-between items-center w-full text-left space-x-5"
        onClick={() => setIsShow(!isShow)}
      >
        <div>
          <h3 className="text-secondary mb-3">{heading}</h3>
          {isShow && <p className={clsx("pl-14 text-secondary", isShow && 'mb-3.5')}>{time}</p>}
        </div>
        <div>
          {isShow ? (
            <ClosedEye className="w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] shrink-0 fill-white" />
          ) : (
            <OpenedEye className="w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] shrink-0 fill-white" />
          )}
        </div>
      </button>
      {isShow && <p>{description}</p>}
      {subTopic && (
        <button onClick={() => setIsShow(!isShow)}>
          <h3 className={clsx("text-secondary mb-5", isShow && 'pl-14 mt-5')}>{subTopic}</h3>
        </button>
      )}
      {!isShow && <p className={clsx("pl-14 text-secondary", isShow && 'mb-3.5')}>{time}</p>}
      {isShow && <p>{subDescription}</p>}
    </div>
  );
};
