import React from "react";
import { ReactComponent as CircleIcon2 } from "../../assets/icons/circleIcon2.svg";

const iconsStyleS =
  "absolute w-[16px] top-[-17px] left-[7px] sm:w-[18px] sm:top-[-15.5px] sm:left-[7px] md:w-[28px] md:top-[-5.5px] md:left-[12px] lg:w-[45px] lg:top-[10px] lg:left-[20px] 2xl:w-[70px] 2xl:top-[35px] 2xl:left-[30px]";
const spanStyle = "relative";

const LetterS = () => {
  return (
    <span className={spanStyle}>
      <CircleIcon2 className={iconsStyleS} />с
    </span>
  );
};

export default LetterS;
