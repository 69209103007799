export const content = [
  {
    heading: "1. Отборочный тур",
    time: "1,5 недели",
    description:
      "Для участия в образовательной программе сначала нужно заполнить анкету, рассказав о себе. После отбора на основе анкеты тебя может ждать интервью с представителем отдела HR. Это своего рода беседа, где ты можешь дополнительно рассказать о себе и показать свой потенциал. Если все прошло успешно, тебе пришлют приглашение начать обучение. Так что, помимо анкеты, готовься к дружелюбному чату с HR – они просто хотят узнать больше о тебе и твоих навыках!",
    subTopic: "",
    subDescription: "",
  },
  {
    heading: "2. Теоретическая часть",
    time: "1 неделя",
    description:
      "Для участия в образовательной программе сначала нужно заполнить анкету, рассказав о себе. После отбора на основе анкеты тебя может ждать интервью с представителем отдела HR. Это своего рода беседа, где ты можешь дополнительно рассказать о себе и показать свой потенциал. Если все прошло успешно, тебе пришлют приглашение начать обучение. Так что, помимо анкеты, готовься к дружелюбному чату с HR – они просто хотят узнать больше о тебе и твоих навыках!",
    subTopic: "2.1 Тест по теории",
    subDescription:
      "После теоретической части вас ждет тест на проверку усвоенной информации. Успешное его прохождение открывает доступ к практическому этапу обучения, где вы применяете полученные знания в реальных ситуациях. Так тест становится входным билетом в захватывающее практическое путешествие.",
  },
  {
    heading: "3. Практика",
    time: "2 недели",
    description:
      "На практической части обучения вас ждет настоящий креативный коктейль: вы освоите искусство создания креативов, генерации новых идей и подходов. Мы также научим вас успешно проходить модерацию на платформе Facebook, а затем анализировать результаты ваших кампаний, включая эффективный запуск трафика. Все это позволит вам не только получить теоретические знания, но и реальные навыки для успешной работы в сфере Media Buying.",
    subTopic: "3.1  Оценка практики",
    subDescription:
      "На практической части обучения мы оценим ваши умения по конкретным KPI's, таким как запуск рекламных кампаний. Вы сможете самостоятельно управлять рекламными кабинетами, придумывать творческие идеи и отслеживать эффективность. По результатам оценки мы решим, предложить ли вам работу в этой области. Это даст вам возможность не только применить знания на практике, но и, возможно, начать карьеру в мире маркетинга.",
  },
  {
    heading: "4. Предложение о работе",
    time: "годы кайфа",
    description:
      "При успешном прохождении всех предыдущих этапов, вам будет предложено официальное место в команде! После вашего согласия на предложение о работе, вы становитесь полноценным членом компании и входите в круг тех, кто создает идеи и воплощает их в жизнь. Добро пожаловать в нашу команду, где совместные усилия приводят к успеху!",
    subTopic: "",
    subDescription: "",
  },
];
