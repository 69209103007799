import React from "react";
import { ReactComponent as CircleIcon2 } from "../../../shared/assets/icons/circleIcon2.svg";

const iconsStyleO =
  "absolute w-[14px] top-[-20px] left-[11px] sm:w-[24px] sm:top-[-15px] sm:left-[13px] lg:w-[35px] lg:top-[-8px] lg:left-[17.5px]";
const spanStyle = "relative";

const LetterJU = () => {
  return (
    <span className={spanStyle}>
      <CircleIcon2 className={iconsStyleO} />ю
    </span>
  );
};

export default LetterJU;
