import React, { useState } from "react";
import ContentWrapper from "../../shared/ui/ContentWrapper/ContentWrapper";
import styles from "./letter.module.scss";
import clsx from "clsx";
import Button from "../../shared/ui/Button/Button";
import LetterModal from "./LetterModal/LetterModal";

const Letter = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <ContentWrapper>
      <div
        className={clsx(
          "w-full max-w-[1350px] h-[355px] sm:h-[520px] lg:h-[556px] flex flex-col justify-center items-center mx-auto rounded-[20px]",
          styles.letter
        )}
      >
        {showModal && <LetterModal onClose={() => setShowModal(false)} />}
        <div className="mt-auto mb-10 flex flex-col items-center justify-center">
          <h2 className="mb-10 text-bgMain text-center px-4">
            Послание от наставников
          </h2>
          <Button
            text="Открыть"
            externalStyles="flex justify-center items-center border-bgMain w-[273px] lg:w-[294px] h-[87px] hover:opacity-90"
            innerStyles="px-8 py-5 w-[236px] lg:w-[254px] bg-bgMain rounded-[10px]"
            onClickHandler={() => setShowModal(!showModal)}
          />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Letter;
