import React from "react";
import LetterO from "../../shared/ui/LetterCircles/LetterO";
import Button from "../../shared/ui/Button/Button";
import logo from "../../shared/assets/images/logo.png";
import LetterJU from "../../shared/ui/LetterCircles/LetterJU";

const listStyles =
  "text-center max-w-[400px] py-[22px] px-5 lg:px-[60px] xl:px-[90px] px-auto border lg:border-y-none lg:border-x border-white min-w-[187px]";

const Contacts = () => {
  return (
    <div
      id="contacts"
      className="w-full pt-[81px] flex flex-col justify-center items-center bg-bgMain z-10 relative"
    >
      <div className="flex justify-center">
        <h2 className="text-center mb-10">
          Мы ждем тебя,
          <br /> д<LetterO /> встречи
          <br /> на следу
          <LetterJU />
          щем этапе!
        </h2>
      </div>

      <Button
        text="Подать заявку"
        externalStyles="flex justify-center items-center border-secondary w-[273px] lg:w-[440px] h-[87px] hover:opacity-90 mb-[30px]"
        innerStyles="px-8 py-5 w-[236px] lg:w-[382px] bg-secondary rounded-[10px]"
        link="https://forms.gle/hLUC3rckSyQ4vCFj9"
      />
      <img src={logo} alt="Logo" className="w-[250px] mb-[48px]" />
      <div className="flex w-full justify-center items-center border-y border-white mb-10">
        <ul className="flex w-[90%] justify-center items-center flex-wrap">
          <li className={listStyles}>
            <a href="mailto:hr@unityconsulting.net" target="blank">
              hr@unityconsulting.net
            </a>
          </li>
          <li className={listStyles}>
            <a href="https://t.me/Aleksandra_HRD" target="blank">
              @Aleksandra_HRD
            </a>
          </li>
          <li className={listStyles}>
            <a href="https://unity-cpa.team/" target="blank">
              unity-cpa.team
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contacts;
