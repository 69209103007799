import React from "react";
import Description from "./Description/Description";
import { ReactComponent as CircleIconWelcome } from "../../shared/assets/icons/circleIconWelcome.svg";
import LetterO from "../../shared/ui/LetterCircles/LetterO";

const About = () => {
  return (
    <div>
      <div
        id="about"
        className="relative overflow-hidden pt-20 pb-36 lg:pt-[208px] lg:pb-[172px] flex justify-center items-center lg:border-b border-white z-10 w-full"
      >
        <div className="px-5 lg:px-20 w-full flex items-center justify-center">
          <h2 className="text-center max-w-[1025px] w-full relative">
            <span className="non absolute top-[-20px] lg:static -rotate-12 lg:rotate-0">non</span>университет-месячная{" "}
            <span>
              пр
              <LetterO />
              грамма
            </span>{" "}
            <span>
              нескучн
              <LetterO />г<LetterO />
            </span>
            {" "}
            <span>
              <LetterO />
              браз
              <LetterO />
              вания
            </span>
          </h2>
        </div>

        <CircleIconWelcome className="w-[140px] h-[140px] lg:w-[286px] lg:h-[286px] absolute bottom-0 lg:bottom-[-50px] lg:left-[-100px] right-[-50px]" />
      </div>
      <Description />
    </div>
  );
};

export default About;
